msgid ""
msgstr ""
"POT-Creation-Date: 2024-08-04 21:58+0800\n"
"MIME-Version: 1.0\n"
"Content-Type: text/plain; charset=utf-8\n"
"Content-Transfer-Encoding: 8bit\n"
"X-Generator: @lingui/cli\n"
"Language: en\n"
"Project-Id-Version: \n"
"Report-Msgid-Bugs-To: \n"
"PO-Revision-Date: \n"
"Last-Translator: \n"
"Language-Team: \n"
"Plural-Forms: \n"

#: src/components/account-block.jsx:135
msgid "Locked"
msgstr ""

#: src/components/account-block.jsx:141
msgid "Posts: {0}"
msgstr ""

#: src/components/account-block.jsx:146
msgid "Last posted: {0}"
msgstr ""

#: src/components/account-block.jsx:161
#: src/components/account-info.jsx:628
msgid "Automated"
msgstr ""

#: src/components/account-block.jsx:168
#: src/components/account-info.jsx:633
#: src/components/status.jsx:494
#: src/pages/catchup.jsx:1471
msgid "Group"
msgstr ""

#: src/components/account-block.jsx:178
msgid "Mutual"
msgstr ""

#: src/components/account-block.jsx:182
#: src/components/account-info.jsx:1713
msgid "Requested"
msgstr ""

#: src/components/account-block.jsx:186
#: src/components/account-info.jsx:416
#: src/components/account-info.jsx:736
#: src/components/account-info.jsx:750
#: src/components/account-info.jsx:1704
#: src/components/nav-menu.jsx:197
#: src/components/shortcuts-settings.jsx:160
#: src/pages/following.jsx:20
#: src/pages/following.jsx:134
msgid "Following"
msgstr ""

#: src/components/account-block.jsx:190
#: src/components/account-info.jsx:1060
msgid "Follows you"
msgstr ""

#: src/components/account-block.jsx:198
msgid "{followersCount, plural, one {# follower} other {# followers}}"
msgstr ""

#: src/components/account-block.jsx:207
#: src/components/account-info.jsx:674
msgid "Verified"
msgstr ""

#: src/components/account-block.jsx:222
#: src/components/account-info.jsx:771
msgid "Joined <0>{0}</0>"
msgstr ""

#: src/components/account-info.jsx:57
msgid "Forever"
msgstr ""

#: src/components/account-info.jsx:377
msgid "Unable to load account."
msgstr ""

#: src/components/account-info.jsx:385
msgid "Go to account page"
msgstr ""

#: src/components/account-info.jsx:413
#: src/components/account-info.jsx:696
#: src/components/account-info.jsx:726
msgid "Followers"
msgstr ""

#: src/components/account-info.jsx:419
#: src/components/account-info.jsx:767
#: src/pages/account-statuses.jsx:515
#: src/pages/search.jsx:237
#: src/pages/search.jsx:384
msgid "Posts"
msgstr ""

#: src/components/account-info.jsx:427
#: src/components/account-info.jsx:1116
#: src/components/compose.jsx:2540
#: src/components/media-alt-modal.jsx:45
#: src/components/media-modal.jsx:283
#: src/components/status.jsx:1736
#: src/components/status.jsx:1753
#: src/components/status.jsx:2389
#: src/components/status.jsx:2392
#: src/pages/account-statuses.jsx:559
#: src/pages/accounts.jsx:109
#: src/pages/hashtag.jsx:199
#: src/pages/list.jsx:157
#: src/pages/public.jsx:131
#: src/pages/status.jsx:1178
#: src/pages/trending.jsx:471
msgid "More"
msgstr ""

#: src/components/account-info.jsx:439
msgid "<0>{displayName}</0> has indicated that their new account is now:"
msgstr ""

#: src/components/account-info.jsx:584
#: src/components/account-info.jsx:1174
msgid "Handle copied"
msgstr ""

#: src/components/account-info.jsx:587
#: src/components/account-info.jsx:1177
msgid "Unable to copy handle"
msgstr ""

#: src/components/account-info.jsx:593
msgid "Copy handle"
msgstr ""

#: src/components/account-info.jsx:600
msgid "View profile image"
msgstr ""

#: src/components/account-info.jsx:606
msgid "View profile header"
msgstr ""

#: src/components/account-info.jsx:623
msgid "In Memoriam"
msgstr ""

#: src/components/account-info.jsx:703
#: src/components/account-info.jsx:741
msgid "This user has chosen to not make this information available."
msgstr ""

#: src/components/account-info.jsx:796
msgid "{0} original posts, {1} replies, {2} boosts"
msgstr ""

#: src/components/account-info.jsx:812
msgid "{0, plural, one {{1, plural, one {Last 1 post in the past 1 day} other {Last 1 post in the past {2} days}}} other {{3, plural, one {Last {4} posts in the past 1 day} other {Last {5} posts in the past {6} days}}}}"
msgstr ""

#: src/components/account-info.jsx:825
msgid "{0, plural, one {Last 1 post in the past year(s)} other {Last {1} posts in the past year(s)}}"
msgstr ""

#: src/components/account-info.jsx:849
#: src/pages/catchup.jsx:70
msgid "Original"
msgstr ""

#: src/components/account-info.jsx:853
#: src/components/status.jsx:2180
#: src/pages/catchup.jsx:71
#: src/pages/catchup.jsx:1445
#: src/pages/catchup.jsx:2056
#: src/pages/status.jsx:901
#: src/pages/status.jsx:1503
msgid "Replies"
msgstr ""

#: src/components/account-info.jsx:857
#: src/pages/catchup.jsx:72
#: src/pages/catchup.jsx:1447
#: src/pages/catchup.jsx:2068
#: src/pages/settings.jsx:1129
msgid "Boosts"
msgstr ""

#: src/components/account-info.jsx:863
msgid "Post stats unavailable."
msgstr ""

#: src/components/account-info.jsx:894
msgid "View post stats"
msgstr ""

#: src/components/account-info.jsx:1064
msgid "Last post: <0>{0}</0>"
msgstr ""

#: src/components/account-info.jsx:1078
msgid "Muted"
msgstr ""

#: src/components/account-info.jsx:1083
msgid "Blocked"
msgstr ""

#: src/components/account-info.jsx:1092
msgid "Private note"
msgstr ""

#: src/components/account-info.jsx:1149
msgid "Mention"
msgstr "Mention"

#: src/components/account-info.jsx:1161
msgid "Translate bio"
msgstr ""

#: src/components/account-info.jsx:1188
msgid "Handle"
msgstr "Handle"

#: src/components/account-info.jsx:1201
msgid "Permalink copied"
msgstr "Permalink copied"

#: src/components/account-info.jsx:1204
msgid "Unable to copy permalink"
msgstr "Unable to copy permalink"

#: src/components/account-info.jsx:1215
msgid "This page"
msgstr "This page"

#: src/components/account-info.jsx:1232
#: src/components/status.jsx:1146
msgid "On your server"
msgstr "On your server"

#: src/components/account-info.jsx:1250
#: src/components/status.jsx:1164
msgid "On the original server"
msgstr "On the original server"

#: src/components/account-info.jsx:1269
msgid "Edit private note"
msgstr ""

#: src/components/account-info.jsx:1269
msgid "Add private note"
msgstr ""

#: src/components/account-info.jsx:1285
#: src/components/account-info.jsx:1907
msgid "Add/Remove from Lists"
msgstr ""

#: src/components/account-info.jsx:1295
msgid "Add to list"
msgstr "Add to list"

#: src/components/account-info.jsx:1319
msgid "Notifications enabled for @{username}'s posts."
msgstr ""

#: src/components/account-info.jsx:1320
msgid "Notifications disabled for @{username}'s posts."
msgstr ""

#: src/components/account-info.jsx:1332
msgid "Disable post notifications"
msgstr "Disable post notifications"

#: src/components/account-info.jsx:1333
msgid "Enable post notifications"
msgstr "Enable post notifications"

#: src/components/account-info.jsx:1350
msgid "Boosts from @{username} muted."
msgstr "Boosts from @{username} muted."

#: src/components/account-info.jsx:1351
msgid "Boosts from @{username} unmuted."
msgstr "Boosts from @{username} unmuted."

#: src/components/account-info.jsx:1362
msgid "Mute boosts"
msgstr "Mute boosts"

#: src/components/account-info.jsx:1362
msgid "Unmute boosts"
msgstr "Unmute boosts"

#: src/components/account-info.jsx:1379
msgid "Unmuted @{username}"
msgstr ""

#: src/components/account-info.jsx:1391
msgid "Unmute <0>@{username}</0>"
msgstr ""

#: src/components/account-info.jsx:1407
msgid "Mute <0>@{username}</0>…"
msgstr ""

#: src/components/account-info.jsx:1439
msgid "Muted @{username} for {0}"
msgstr ""

#: src/components/account-info.jsx:1451
msgid "Unable to mute @{username}"
msgstr ""

#: src/components/account-info.jsx:1478
msgid "Remove <0>@{username}</0> from followers?"
msgstr ""

#: src/components/account-info.jsx:1498
msgid "@{username} removed from followers"
msgstr ""

#: src/components/account-info.jsx:1510
msgid "Remove follower…"
msgstr ""

#: src/components/account-info.jsx:1521
msgid "Block <0>@{username}</0>?"
msgstr ""

#: src/components/account-info.jsx:1545
msgid "Unblocked @{username}"
msgstr ""

#: src/components/account-info.jsx:1553
msgid "Blocked @{username}"
msgstr ""

#: src/components/account-info.jsx:1561
msgid "Unable to unblock @{username}"
msgstr ""

#: src/components/account-info.jsx:1563
msgid "Unable to block @{username}"
msgstr ""

#: src/components/account-info.jsx:1573
msgid "Unblock <0>@{username}</0>"
msgstr ""

#: src/components/account-info.jsx:1582
msgid "Block <0>@{username}</0>…"
msgstr ""

#: src/components/account-info.jsx:1599
msgid "Report <0>@{username}</0>…"
msgstr ""

#: src/components/account-info.jsx:1619
#: src/components/account-info.jsx:2140
msgid "Edit profile"
msgstr ""

#: src/components/account-info.jsx:1655
msgid "Withdraw follow request?"
msgstr ""

#: src/components/account-info.jsx:1656
msgid "Unfollow @{0}?"
msgstr ""

#: src/components/account-info.jsx:1707
msgid "Unfollow…"
msgstr ""

#: src/components/account-info.jsx:1716
msgid "Withdraw…"
msgstr ""

#: src/components/account-info.jsx:1723
#: src/components/account-info.jsx:1727
#: src/pages/hashtag.jsx:261
msgid "Follow"
msgstr ""

#: src/components/account-info.jsx:1848
#: src/components/account-info.jsx:1902
#: src/components/account-info.jsx:2035
#: src/components/account-info.jsx:2135
#: src/components/account-sheet.jsx:37
#: src/components/compose.jsx:829
#: src/components/compose.jsx:2496
#: src/components/compose.jsx:2969
#: src/components/compose.jsx:3177
#: src/components/compose.jsx:3407
#: src/components/drafts.jsx:58
#: src/components/embed-modal.jsx:12
#: src/components/generic-accounts.jsx:142
#: src/components/keyboard-shortcuts-help.jsx:39
#: src/components/list-add-edit.jsx:33
#: src/components/media-alt-modal.jsx:33
#: src/components/media-modal.jsx:247
#: src/components/notification-service.jsx:156
#: src/components/report-modal.jsx:75
#: src/components/shortcuts-settings.jsx:268
#: src/components/shortcuts-settings.jsx:621
#: src/components/shortcuts-settings.jsx:821
#: src/components/status.jsx:2891
#: src/components/status.jsx:3130
#: src/components/status.jsx:3629
#: src/pages/accounts.jsx:36
#: src/pages/catchup.jsx:1581
#: src/pages/filters.jsx:224
#: src/pages/list.jsx:274
#: src/pages/notifications.jsx:868
#: src/pages/notifications.jsx:1082
#: src/pages/settings.jsx:76
#: src/pages/status.jsx:1265
msgid "Close"
msgstr ""

#: src/components/account-info.jsx:1853
msgid "Translated Bio"
msgstr ""

#: src/components/account-info.jsx:1947
msgid "Unable to remove from list."
msgstr ""

#: src/components/account-info.jsx:1948
msgid "Unable to add to list."
msgstr ""

#: src/components/account-info.jsx:1967
#: src/pages/lists.jsx:104
msgid "Unable to load lists."
msgstr ""

#: src/components/account-info.jsx:1971
msgid "No lists."
msgstr ""

#: src/components/account-info.jsx:1982
#: src/components/list-add-edit.jsx:37
#: src/pages/lists.jsx:58
msgid "New list"
msgstr ""

#: src/components/account-info.jsx:2040
msgid "Private note about <0>@{0}</0>"
msgstr ""

#: src/components/account-info.jsx:2070
msgid "Unable to update private note."
msgstr ""

#: src/components/account-info.jsx:2093
#: src/components/account-info.jsx:2263
msgid "Cancel"
msgstr ""

#: src/components/account-info.jsx:2098
msgid "Save & close"
msgstr ""

#: src/components/account-info.jsx:2191
msgid "Unable to update profile."
msgstr ""

#: src/components/account-info.jsx:2198
#: src/components/list-add-edit.jsx:102
msgid "Name"
msgstr ""

#: src/components/account-info.jsx:2211
msgid "Bio"
msgstr ""

#: src/components/account-info.jsx:2224
msgid "Extra fields"
msgstr ""

#: src/components/account-info.jsx:2230
msgid "Label"
msgstr ""

#: src/components/account-info.jsx:2233
msgid "Content"
msgstr ""

#: src/components/account-info.jsx:2266
#: src/components/list-add-edit.jsx:147
#: src/components/shortcuts-settings.jsx:753
#: src/pages/filters.jsx:554
#: src/pages/notifications.jsx:934
msgid "Save"
msgstr ""

#: src/components/account-info.jsx:2319
msgid "username"
msgstr ""

#: src/components/account-info.jsx:2323
msgid "server domain name"
msgstr ""

#: src/components/background-service.jsx:149
msgid "Cloak mode disabled"
msgstr ""

#: src/components/background-service.jsx:149
msgid "Cloak mode enabled"
msgstr ""

#: src/components/columns.jsx:20
#: src/components/nav-menu.jsx:188
#: src/components/shortcuts-settings.jsx:160
#: src/components/timeline.jsx:437
#: src/pages/catchup.jsx:876
#: src/pages/filters.jsx:89
#: src/pages/followed-hashtags.jsx:40
#: src/pages/home.jsx:53
#: src/pages/notifications.jsx:520
msgid "Home"
msgstr ""

#: src/components/compose-button.jsx:49
#: src/compose.jsx:37
msgid "Compose"
msgstr ""

#: src/components/compose.jsx:77
msgid "Plain text"
msgstr "Plain text"

#: src/components/compose.jsx:78
msgid "HTML"
msgstr "HTML"

#: src/components/compose.jsx:79
msgid "Markdown"
msgstr "Markdown"

#: src/components/compose.jsx:80
msgid "BBCode"
msgstr "BBCode"

#: src/components/compose.jsx:81
msgid "MFM"
msgstr "MFM"

#: src/components/compose.jsx:405
msgid "You have unsaved changes. Discard this post?"
msgstr ""

#: src/components/compose.jsx:633
msgid "{0, plural, one {File {1} is not supported.} other {Files {2} are not supported.}}"
msgstr "{0, plural, one {File {1} is not supported.} other {Files {2} are not supported.}}"

#: src/components/compose.jsx:643
#: src/components/compose.jsx:661
#: src/components/compose.jsx:1413
#: src/components/compose.jsx:1675
msgid "{maxMediaAttachments, plural, one {You can only attach up to 1 file.} other {You can only attach up to # files.}}"
msgstr ""

#: src/components/compose.jsx:810
msgid "Pop out"
msgstr ""

#: src/components/compose.jsx:817
msgid "Minimize"
msgstr ""

#: src/components/compose.jsx:853
msgid "Looks like you closed the parent window."
msgstr ""

#: src/components/compose.jsx:860
msgid "Looks like you already have a compose field open in the parent window and currently publishing. Please wait for it to be done and try again later."
msgstr ""

#: src/components/compose.jsx:865
msgid "Looks like you already have a compose field open in the parent window. Popping in this window will discard the changes you made in the parent window. Continue?"
msgstr ""

#: src/components/compose.jsx:907
msgid "Pop in"
msgstr ""

#: src/components/compose.jsx:917
msgid "Replying to @{0}’s post (<0>{1}</0>)"
msgstr ""

#: src/components/compose.jsx:927
msgid "Replying to @{0}’s post"
msgstr ""

#: src/components/compose.jsx:940
msgid "Editing source post"
msgstr ""

#: src/components/compose.jsx:987
msgid "Poll must have at least 2 options"
msgstr ""

#: src/components/compose.jsx:991
msgid "Some poll choices are empty"
msgstr ""

#: src/components/compose.jsx:1004
msgid "Some media have no descriptions. Continue?"
msgstr ""

#: src/components/compose.jsx:1056
msgid "Attachment #{i} failed"
msgstr ""

#: src/components/compose.jsx:1157
#: src/components/status.jsx:1974
#: src/components/timeline.jsx:983
msgid "Content warning"
msgstr ""

#: src/components/compose.jsx:1173
msgid "Content warning or sensitive media"
msgstr ""

#: src/components/compose.jsx:1240
#: src/components/status.jsx:93
#: src/pages/settings.jsx:304
msgid "Public"
msgstr ""

#: src/components/compose.jsx:1245
#: src/components/nav-menu.jsx:390
#: src/components/shortcuts-settings.jsx:187
#: src/components/status.jsx:94
msgid "Local"
msgstr ""

#: src/components/compose.jsx:1249
#: src/components/status.jsx:95
#: src/pages/settings.jsx:307
msgid "Unlisted"
msgstr ""

#: src/components/compose.jsx:1252
#: src/components/status.jsx:96
#: src/pages/settings.jsx:310
msgid "Followers only"
msgstr ""

#: src/components/compose.jsx:1257
msgid "List only"
msgstr "List only"

#: src/components/compose.jsx:1261
#: src/components/status.jsx:98
msgid "Private mention"
msgstr ""

#: src/components/compose.jsx:1270
msgid "Post your reply"
msgstr ""

#: src/components/compose.jsx:1272
msgid "Edit your post"
msgstr ""

#: src/components/compose.jsx:1273
msgid "What are you doing?"
msgstr ""

#: src/components/compose.jsx:1351
msgid "Mark media as sensitive"
msgstr ""

#: src/components/compose.jsx:1449
msgid "Add poll"
msgstr ""

#: src/components/compose.jsx:1471
msgid "Add custom emoji"
msgstr ""

#: src/components/compose.jsx:1556
#: src/components/keyboard-shortcuts-help.jsx:143
#: src/components/status.jsx:912
#: src/components/status.jsx:1716
#: src/components/status.jsx:1717
#: src/components/status.jsx:2285
msgid "Reply"
msgstr ""

#: src/components/compose.jsx:1558
msgid "Update"
msgstr ""

#: src/components/compose.jsx:1559
msgctxt "Submit button in composer"
msgid "Post"
msgstr ""

#: src/components/compose.jsx:1687
msgid "Downloading GIF…"
msgstr ""

#: src/components/compose.jsx:1715
msgid "Failed to download GIF"
msgstr ""

#: src/components/compose.jsx:1827
#: src/components/compose.jsx:1904
#: src/components/nav-menu.jsx:291
msgid "More…"
msgstr ""

#: src/components/compose.jsx:2309
msgid "Uploaded"
msgstr ""

#: src/components/compose.jsx:2322
msgid "Image description"
msgstr ""

#: src/components/compose.jsx:2323
msgid "Video description"
msgstr ""

#: src/components/compose.jsx:2324
msgid "Audio description"
msgstr ""

#: src/components/compose.jsx:2360
#: src/components/compose.jsx:2380
msgid "File size too large. Uploading might encounter issues. Try reduce the file size from {0} to {1} or lower."
msgstr ""

#: src/components/compose.jsx:2372
#: src/components/compose.jsx:2392
msgid "Dimension too large. Uploading might encounter issues. Try reduce dimension from {0}×{1}px to {2}×{3}px."
msgstr ""

#: src/components/compose.jsx:2400
msgid "Frame rate too high. Uploading might encounter issues."
msgstr ""

#: src/components/compose.jsx:2460
#: src/components/compose.jsx:2710
#: src/components/shortcuts-settings.jsx:764
#: src/pages/catchup.jsx:1074
#: src/pages/filters.jsx:412
msgid "Remove"
msgstr ""

#: src/components/compose.jsx:2477
#: src/compose.jsx:83
msgid "Error"
msgstr ""

#: src/components/compose.jsx:2502
msgid "Edit image description"
msgstr ""

#: src/components/compose.jsx:2503
msgid "Edit video description"
msgstr ""

#: src/components/compose.jsx:2504
msgid "Edit audio description"
msgstr ""

#: src/components/compose.jsx:2549
#: src/components/compose.jsx:2598
msgid "Generating description. Please wait…"
msgstr ""

#: src/components/compose.jsx:2569
msgid "Failed to generate description: {0}"
msgstr ""

#: src/components/compose.jsx:2570
msgid "Failed to generate description"
msgstr ""

#: src/components/compose.jsx:2582
#: src/components/compose.jsx:2588
#: src/components/compose.jsx:2634
msgid "Generate description…"
msgstr ""

#: src/components/compose.jsx:2621
msgid "Failed to generate description{0}"
msgstr ""

#: src/components/compose.jsx:2636
msgid "({0}) <0>— experimental</0>"
msgstr ""

#: src/components/compose.jsx:2655
msgid "Done"
msgstr ""

#: src/components/compose.jsx:2691
msgid "Choice {0}"
msgstr ""

#: src/components/compose.jsx:2738
msgid "Multiple choices"
msgstr ""

#: src/components/compose.jsx:2741
msgid "Duration"
msgstr ""

#: src/components/compose.jsx:2772
msgid "Remove poll"
msgstr ""

#: src/components/compose.jsx:2986
msgid "Search accounts"
msgstr ""

#: src/components/compose.jsx:3027
#: src/components/shortcuts-settings.jsx:753
#: src/pages/list.jsx:359
msgid "Add"
msgstr ""

#: src/components/compose.jsx:3040
#: src/components/generic-accounts.jsx:227
msgid "Error loading accounts"
msgstr ""

#: src/components/compose.jsx:3183
msgid "Custom emojis"
msgstr ""

#: src/components/compose.jsx:3203
msgid "Search emoji"
msgstr ""

#: src/components/compose.jsx:3234
msgid "Error loading custom emojis"
msgstr ""

#: src/components/compose.jsx:3245
msgid "Recently used"
msgstr ""

#: src/components/compose.jsx:3246
msgid "Others"
msgstr ""

#: src/components/compose.jsx:3284
msgid "{0} more…"
msgstr ""

#: src/components/compose.jsx:3422
msgid "Search GIFs"
msgstr ""

#: src/components/compose.jsx:3437
msgid "Powered by GIPHY"
msgstr ""

#: src/components/compose.jsx:3445
msgid "Type to search GIFs"
msgstr ""

#: src/components/compose.jsx:3543
#: src/components/media-modal.jsx:387
#: src/components/timeline.jsx:889
msgid "Previous"
msgstr ""

#: src/components/compose.jsx:3561
#: src/components/media-modal.jsx:406
#: src/components/timeline.jsx:906
msgid "Next"
msgstr ""

#: src/components/compose.jsx:3578
msgid "Error loading GIFs"
msgstr ""

#: src/components/drafts.jsx:63
#: src/pages/settings.jsx:710
msgid "Unsent drafts"
msgstr ""

#: src/components/drafts.jsx:68
msgid "Looks like you have unsent drafts. Let's continue where you left off."
msgstr ""

#: src/components/drafts.jsx:102
msgid "Delete this draft?"
msgstr ""

#: src/components/drafts.jsx:117
msgid "Error deleting draft! Please try again."
msgstr ""

#: src/components/drafts.jsx:127
#: src/components/list-add-edit.jsx:183
#: src/components/status.jsx:1346
#: src/pages/filters.jsx:587
msgid "Delete…"
msgstr ""

#: src/components/drafts.jsx:146
msgid "Error fetching reply-to status!"
msgstr ""

#: src/components/drafts.jsx:171
msgid "Delete all drafts?"
msgstr ""

#: src/components/drafts.jsx:189
msgid "Error deleting drafts! Please try again."
msgstr ""

#: src/components/drafts.jsx:201
msgid "Delete all…"
msgstr ""

#: src/components/drafts.jsx:209
msgid "No drafts found."
msgstr ""

#: src/components/drafts.jsx:245
#: src/pages/catchup.jsx:1928
msgid "Poll"
msgstr ""

#: src/components/drafts.jsx:248
#: src/components/shortcuts-settings.jsx:118
#: src/pages/account-statuses.jsx:396
msgid "Media"
msgstr ""

#: src/components/embed-modal.jsx:22
msgid "Open in new window"
msgstr ""

#: src/components/follow-request-buttons.jsx:42
#: src/pages/notifications.jsx:918
msgid "Accept"
msgstr ""

#: src/components/follow-request-buttons.jsx:68
msgid "Reject"
msgstr ""

#: src/components/follow-request-buttons.jsx:75
#: src/pages/notifications.jsx:1201
msgid "Accepted"
msgstr ""

#: src/components/follow-request-buttons.jsx:79
msgid "Rejected"
msgstr ""

#: src/components/generic-accounts.jsx:24
msgid "Nothing to show"
msgstr ""

#: src/components/generic-accounts.jsx:145
#: src/components/notification.jsx:438
#: src/pages/accounts.jsx:41
#: src/pages/search.jsx:227
#: src/pages/search.jsx:260
msgid "Accounts"
msgstr ""

#: src/components/generic-accounts.jsx:205
#: src/components/timeline.jsx:519
#: src/pages/list.jsx:293
#: src/pages/notifications.jsx:848
#: src/pages/search.jsx:454
#: src/pages/status.jsx:1298
msgid "Show more…"
msgstr ""

#: src/components/generic-accounts.jsx:210
#: src/components/timeline.jsx:524
#: src/pages/search.jsx:459
msgid "The end."
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:43
#: src/components/nav-menu.jsx:417
#: src/pages/catchup.jsx:1619
msgid "Keyboard shortcuts"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:51
msgid "Keyboard shortcuts help"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:55
#: src/pages/catchup.jsx:1644
msgid "Next post"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:59
#: src/pages/catchup.jsx:1652
msgid "Previous post"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:63
msgid "Skip carousel to next post"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:65
msgid "<0>Shift</0> + <1>j</1>"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:71
msgid "Skip carousel to previous post"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:73
msgid "<0>Shift</0> + <1>k</1>"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:79
msgid "Load new posts"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:83
#: src/pages/catchup.jsx:1676
msgid "Open post details"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:85
msgid "<0>Enter</0> or <1>o</1>"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:92
msgid "Expand content warning or<0/>toggle expanded/collapsed thread"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:101
msgid "Close post or dialogs"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:103
msgid "<0>Esc</0> or <1>Backspace</1>"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:109
msgid "Focus column in multi-column mode"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:111
msgid "<0>1</0> to <1>9</1>"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:117
msgid "Compose new post"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:121
msgid "Compose new post (new window)"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:124
msgid "<0>Shift</0> + <1>c</1>"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:130
msgid "Send post"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:132
msgid "<0>Ctrl</0> + <1>Enter</1> or <2>⌘</2> + <3>Enter</3>"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:139
#: src/components/nav-menu.jsx:378
#: src/components/search-form.jsx:72
#: src/components/shortcuts-settings.jsx:51
#: src/components/shortcuts-settings.jsx:216
#: src/pages/search.jsx:39
#: src/pages/search.jsx:209
msgid "Search"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:147
msgid "Reply (new window)"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:150
msgid "<0>Shift</0> + <1>r</1>"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:156
msgid "Like (favourite)"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:158
msgid "<0>l</0> or <1>f</1>"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:164
#: src/components/status.jsx:920
#: src/components/status.jsx:2311
#: src/components/status.jsx:2343
#: src/components/status.jsx:2344
msgid "Boost"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:166
msgid "<0>Shift</0> + <1>b</1>"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:172
#: src/components/status.jsx:1007
#: src/components/status.jsx:2368
#: src/components/status.jsx:2369
msgid "Bookmark"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:176
msgid "Toggle Cloak mode"
msgstr ""

#: src/components/keyboard-shortcuts-help.jsx:178
msgid "<0>Shift</0> + <1>Alt</1> + <2>k</2>"
msgstr ""

#: src/components/list-add-edit.jsx:37
msgid "Edit list"
msgstr ""

#: src/components/list-add-edit.jsx:93
msgid "Unable to edit list."
msgstr ""

#: src/components/list-add-edit.jsx:94
msgid "Unable to create list."
msgstr ""

#: src/components/list-add-edit.jsx:122
msgid "Show replies to list members"
msgstr ""

#: src/components/list-add-edit.jsx:125
msgid "Show replies to people I follow"
msgstr ""

#: src/components/list-add-edit.jsx:128
msgid "Don't show replies"
msgstr ""

#: src/components/list-add-edit.jsx:141
msgid "Hide posts on this list from Home/Following"
msgstr ""

#: src/components/list-add-edit.jsx:147
#: src/pages/filters.jsx:554
msgid "Create"
msgstr ""

#: src/components/list-add-edit.jsx:154
msgid "Delete this list?"
msgstr ""

#: src/components/list-add-edit.jsx:173
msgid "Unable to delete list."
msgstr ""

#: src/components/media-alt-modal.jsx:38
#: src/components/media.jsx:50
msgid "Media description"
msgstr ""

#: src/components/media-alt-modal.jsx:57
#: src/components/status.jsx:1060
#: src/components/status.jsx:1089
#: src/components/translation-block.jsx:195
msgid "Translate"
msgstr ""

#: src/components/media-alt-modal.jsx:68
#: src/components/status.jsx:1074
#: src/components/status.jsx:1103
msgid "Speak"
msgstr ""

#: src/components/media-modal.jsx:294
msgid "Open original media in new window"
msgstr ""

#: src/components/media-modal.jsx:298
msgid "Open original media"
msgstr ""

#: src/components/media-modal.jsx:314
msgid "Attempting to describe image. Please wait…"
msgstr ""

#: src/components/media-modal.jsx:329
msgid "Failed to describe image"
msgstr ""

#: src/components/media-modal.jsx:339
msgid "Describe image…"
msgstr ""

#: src/components/media-modal.jsx:362
msgid "View post"
msgstr ""

#: src/components/media-post.jsx:127
msgid "Sensitive media"
msgstr ""

#: src/components/media-post.jsx:132
msgid "Filtered: {filterTitleStr}"
msgstr ""

#: src/components/media-post.jsx:133
#: src/components/status.jsx:3459
#: src/components/timeline.jsx:972
#: src/pages/catchup.jsx:75
#: src/pages/catchup.jsx:1876
msgid "Filtered"
msgstr ""

#: src/components/modals.jsx:72
msgid "Post published. Check it out."
msgstr ""

#: src/components/modals.jsx:73
msgid "Reply posted. Check it out."
msgstr ""

#: src/components/modals.jsx:74
msgid "Post updated. Check it out."
msgstr ""

#: src/components/nav-menu.jsx:130
msgid "Menu"
msgstr ""

#: src/components/nav-menu.jsx:166
msgid "Reload page now to update?"
msgstr ""

#: src/components/nav-menu.jsx:178
msgid "New update available…"
msgstr ""

#: src/components/nav-menu.jsx:204
#: src/pages/catchup.jsx:871
msgid "Catch-up"
msgstr ""

#: src/components/nav-menu.jsx:211
#: src/components/shortcuts-settings.jsx:57
#: src/components/shortcuts-settings.jsx:166
#: src/pages/home.jsx:224
#: src/pages/mentions.jsx:20
#: src/pages/mentions.jsx:167
#: src/pages/settings.jsx:1121
#: src/pages/trending.jsx:381
msgid "Mentions"
msgstr ""

#: src/components/nav-menu.jsx:218
#: src/components/shortcuts-settings.jsx:48
#: src/components/shortcuts-settings.jsx:172
#: src/pages/filters.jsx:24
#: src/pages/home.jsx:84
#: src/pages/home.jsx:184
#: src/pages/notifications.jsx:112
#: src/pages/notifications.jsx:524
msgid "Notifications"
msgstr ""

#: src/components/nav-menu.jsx:221
msgid "New"
msgstr ""

#: src/components/nav-menu.jsx:232
#: src/components/shortcuts-settings.jsx:52
msgid "Profile"
msgstr ""

#: src/components/nav-menu.jsx:245
#: src/components/nav-menu.jsx:272
#: src/components/shortcuts-settings.jsx:49
#: src/components/shortcuts-settings.jsx:178
#: src/pages/list.jsx:126
#: src/pages/lists.jsx:16
#: src/pages/lists.jsx:50
msgid "Lists"
msgstr ""

#: src/components/nav-menu.jsx:253
#: src/components/shortcuts.jsx:209
#: src/pages/list.jsx:133
msgid "All Lists"
msgstr ""

#: src/components/nav-menu.jsx:280
#: src/components/shortcuts-settings.jsx:53
#: src/components/shortcuts-settings.jsx:233
#: src/pages/bookmarks.jsx:11
#: src/pages/bookmarks.jsx:23
msgid "Bookmarks"
msgstr ""

#: src/components/nav-menu.jsx:300
#: src/components/shortcuts-settings.jsx:54
#: src/components/shortcuts-settings.jsx:239
#: src/pages/catchup.jsx:1446
#: src/pages/catchup.jsx:2062
#: src/pages/favourites.jsx:11
#: src/pages/favourites.jsx:23
#: src/pages/settings.jsx:1125
msgid "Likes"
msgstr ""

#: src/components/nav-menu.jsx:306
#: src/pages/followed-hashtags.jsx:14
#: src/pages/followed-hashtags.jsx:44
msgid "Followed Hashtags"
msgstr ""

#: src/components/nav-menu.jsx:314
#: src/pages/account-statuses.jsx:362
#: src/pages/filters.jsx:54
#: src/pages/filters.jsx:93
#: src/pages/hashtag.jsx:339
msgid "Filters"
msgstr ""

#: src/components/nav-menu.jsx:322
msgid "Muted users"
msgstr ""

#: src/components/nav-menu.jsx:330
msgid "Muted users…"
msgstr ""

#: src/components/nav-menu.jsx:337
msgid "Blocked users"
msgstr ""

#: src/components/nav-menu.jsx:345
msgid "Blocked users…"
msgstr ""

#: src/components/nav-menu.jsx:357
msgid "Accounts…"
msgstr ""

#: src/components/nav-menu.jsx:367
#: src/pages/login.jsx:189
#: src/pages/status.jsx:801
#: src/pages/welcome.jsx:64
msgid "Log in"
msgstr ""

#: src/components/nav-menu.jsx:384
#: src/components/shortcuts-settings.jsx:56
#: src/components/shortcuts-settings.jsx:209
#: src/pages/trending.jsx:441
msgid "Trending"
msgstr ""

#: src/components/nav-menu.jsx:397
#: src/components/shortcuts-settings.jsx:188
msgid "Bubble"
msgstr "Bubble"

#: src/components/nav-menu.jsx:404
#: src/components/shortcuts-settings.jsx:189
msgid "Federated"
msgstr ""

#: src/components/nav-menu.jsx:427
msgid "Shortcuts / Columns…"
msgstr ""

#: src/components/nav-menu.jsx:437
#: src/components/nav-menu.jsx:451
msgid "Settings…"
msgstr ""

#: src/components/notification-service.jsx:160
msgid "Notification"
msgstr ""

#: src/components/notification-service.jsx:166
msgid "This notification is from your other account."
msgstr ""

#: src/components/notification-service.jsx:195
msgid "View all notifications"
msgstr ""

#: src/components/notification.jsx:70
msgid "{account} reacted to your post with {emojiObject}"
msgstr ""

#: src/components/notification.jsx:77
msgid "{account} published a post."
msgstr ""

#: src/components/notification.jsx:85
msgid "{count, plural, =1 {{postsCount, plural, =1 {{postType, select, reply {{account} boosted your reply.} other {{account} boosted your post.}}} other {{account} boosted {postsCount} of your posts.}}} other {{postType, select, reply {<0><1>{0}</1> people</0> boosted your reply.} other {<2><3>{1}</3> people</2> boosted your post.}}}}"
msgstr ""

#: src/components/notification.jsx:128
msgid "{count, plural, =1 {{account} followed you.} other {<0><1>{0}</1> people</0> followed you.}}"
msgstr ""

#: src/components/notification.jsx:142
msgid "{account} requested to follow you."
msgstr ""

#: src/components/notification.jsx:151
msgid "{count, plural, =1 {{postsCount, plural, =1 {{postType, select, reply {{account} liked your reply.} other {{account} liked your post.}}} other {{account} liked {postsCount} of your posts.}}} other {{postType, select, reply {<0><1>{0}</1> people</0> liked your reply.} other {<2><3>{1}</3> people</2> liked your post.}}}}"
msgstr ""

#: src/components/notification.jsx:193
msgid "A poll you have voted in or created has ended."
msgstr ""

#: src/components/notification.jsx:194
msgid "A poll you have created has ended."
msgstr ""

#: src/components/notification.jsx:195
msgid "A poll you have voted in has ended."
msgstr ""

#: src/components/notification.jsx:196
msgid "A post you interacted with has been edited."
msgstr ""

#: src/components/notification.jsx:204
msgid "{count, plural, =1 {{postsCount, plural, =1 {{postType, select, reply {{account} boosted & liked your reply.} other {{account} boosted & liked your post.}}} other {{account} boosted & liked {postsCount} of your posts.}}} other {{postType, select, reply {<0><1>{0}</1> people</0> boosted & liked your reply.} other {<2><3>{1}</3> people</2> boosted & liked your post.}}}}"
msgstr ""

#: src/components/notification.jsx:246
msgid "{account} signed up."
msgstr ""

#: src/components/notification.jsx:248
msgid "{account} reported {targetAccount}"
msgstr ""

#: src/components/notification.jsx:253
msgid "Lost connections with <0>{name}</0>."
msgstr ""

#: src/components/notification.jsx:259
msgid "Moderation warning"
msgstr ""

#: src/components/notification.jsx:269
msgid "An admin from <0>{from}</0> has suspended <1>{targetName}</1>, which means you can no longer receive updates from them or interact with them."
msgstr ""

#: src/components/notification.jsx:275
msgid "An admin from <0>{from}</0> has blocked <1>{targetName}</1>. Affected followers: {followersCount}, followings: {followingCount}."
msgstr ""

#: src/components/notification.jsx:281
msgid "You have blocked <0>{targetName}</0>. Removed followers: {followersCount}, followings: {followingCount}."
msgstr ""

#: src/components/notification.jsx:289
msgid "Your account has received a moderation warning."
msgstr ""

#: src/components/notification.jsx:290
msgid "Your account has been disabled."
msgstr ""

#: src/components/notification.jsx:291
msgid "Some of your posts have been marked as sensitive."
msgstr ""

#: src/components/notification.jsx:292
msgid "Some of your posts have been deleted."
msgstr ""

#: src/components/notification.jsx:293
msgid "Your posts will be marked as sensitive from now on."
msgstr ""

#: src/components/notification.jsx:294
msgid "Your account has been limited."
msgstr ""

#: src/components/notification.jsx:295
msgid "Your account has been suspended."
msgstr ""

#: src/components/notification.jsx:369
msgid "[Unknown notification type: {type}]"
msgstr ""

#: src/components/notification.jsx:434
#: src/components/status.jsx:1028
#: src/components/status.jsx:1038
msgid "Boosted/Liked by…"
msgstr ""

#: src/components/notification.jsx:435
msgid "Liked by…"
msgstr ""

#: src/components/notification.jsx:436
msgid "Boosted by…"
msgstr ""

#: src/components/notification.jsx:437
msgid "Followed by…"
msgstr ""

#: src/components/notification.jsx:508
#: src/components/notification.jsx:524
msgid "Learn more <0/>"
msgstr ""

#: src/components/notification.jsx:756
#: src/components/status.jsx:221
msgid "Read more →"
msgstr ""

#: src/components/poll.jsx:110
msgid "Voted"
msgstr ""

#: src/components/poll.jsx:116
msgid "{optionVotesCount, plural, one {# vote} other {# votes}}"
msgstr "{optionVotesCount, plural, one {# vote} other {# votes}}"

#: src/components/poll.jsx:136
#: src/components/poll.jsx:219
#: src/components/poll.jsx:223
msgid "Hide results"
msgstr ""

#: src/components/poll.jsx:185
msgid "Vote"
msgstr ""

#: src/components/poll.jsx:205
#: src/components/poll.jsx:207
#: src/pages/status.jsx:1167
#: src/pages/status.jsx:1190
msgid "Refresh"
msgstr ""

#: src/components/poll.jsx:219
#: src/components/poll.jsx:223
msgid "Show results"
msgstr ""

#: src/components/poll.jsx:228
msgid "{votesCount, plural, one {<0>{0}</0> vote} other {<1>{1}</1> votes}}"
msgstr ""

#: src/components/poll.jsx:245
msgid "{votersCount, plural, one {<0>{0}</0> voter} other {<1>{1}</1> voters}}"
msgstr ""

#: src/components/poll.jsx:265
msgid "Ended <0/>"
msgstr ""

#: src/components/poll.jsx:269
msgid "Ended"
msgstr ""

#: src/components/poll.jsx:272
msgid "Ending <0/>"
msgstr ""

#: src/components/poll.jsx:276
msgid "Ending"
msgstr ""

#. Relative time in seconds, as short as possible
#: src/components/relative-time.jsx:57
msgid "{0}s"
msgstr ""

#. Relative time in minutes, as short as possible
#: src/components/relative-time.jsx:62
msgid "{0}m"
msgstr ""

#. Relative time in hours, as short as possible
#: src/components/relative-time.jsx:67
msgid "{0}h"
msgstr ""

#: src/components/report-modal.jsx:29
msgid "Spam"
msgstr ""

#: src/components/report-modal.jsx:30
msgid "Malicious links, fake engagement, or repetitive replies"
msgstr ""

#: src/components/report-modal.jsx:33
msgid "Illegal"
msgstr ""

#: src/components/report-modal.jsx:34
msgid "Violates the law of your or the server's country"
msgstr ""

#: src/components/report-modal.jsx:37
msgid "Server rule violation"
msgstr ""

#: src/components/report-modal.jsx:38
msgid "Breaks specific server rules"
msgstr ""

#: src/components/report-modal.jsx:39
msgid "Violation"
msgstr ""

#: src/components/report-modal.jsx:42
msgid "Other"
msgstr ""

#: src/components/report-modal.jsx:43
msgid "Issue doesn't fit other categories"
msgstr ""

#: src/components/report-modal.jsx:68
msgid "Report Post"
msgstr ""

#: src/components/report-modal.jsx:68
msgid "Report @{username}"
msgstr ""

#: src/components/report-modal.jsx:104
msgid "Pending review"
msgstr ""

#: src/components/report-modal.jsx:146
msgid "Post reported"
msgstr ""

#: src/components/report-modal.jsx:146
msgid "Profile reported"
msgstr ""

#: src/components/report-modal.jsx:154
msgid "Unable to report post"
msgstr ""

#: src/components/report-modal.jsx:155
msgid "Unable to report profile"
msgstr ""

#: src/components/report-modal.jsx:163
msgid "What's the issue with this post?"
msgstr ""

#: src/components/report-modal.jsx:164
msgid "What's the issue with this profile?"
msgstr ""

#: src/components/report-modal.jsx:233
msgid "Additional info"
msgstr ""

#: src/components/report-modal.jsx:256
msgid "Forward to <0>{domain}</0>"
msgstr ""

#: src/components/report-modal.jsx:266
msgid "Send Report"
msgstr ""

#: src/components/report-modal.jsx:275
msgid "Muted {username}"
msgstr ""

#: src/components/report-modal.jsx:278
msgid "Unable to mute {username}"
msgstr ""

#: src/components/report-modal.jsx:283
msgid "Send Report <0>+ Mute profile</0>"
msgstr ""

#: src/components/report-modal.jsx:294
msgid "Blocked {username}"
msgstr ""

#: src/components/report-modal.jsx:297
msgid "Unable to block {username}"
msgstr ""

#: src/components/report-modal.jsx:302
msgid "Send Report <0>+ Block profile</0>"
msgstr ""

#: src/components/search-form.jsx:202
msgid "{query} <0>‒ accounts, hashtags & posts</0>"
msgstr ""

#: src/components/search-form.jsx:215
msgid "Posts with <0>{query}</0>"
msgstr ""

#: src/components/search-form.jsx:227
msgid "Posts tagged with <0>#{0}</0>"
msgstr ""

#: src/components/search-form.jsx:241
msgid "Look up <0>{query}</0>"
msgstr ""

#: src/components/search-form.jsx:252
msgid "Accounts with <0>{query}</0>"
msgstr ""

#: src/components/shortcuts-settings.jsx:47
msgid "Home / Following"
msgstr ""

#: src/components/shortcuts-settings.jsx:50
msgid "Local / Bubble / Federated"
msgstr "Local / Bubble / Federated"

#: src/components/shortcuts-settings.jsx:55
msgid "Hashtag"
msgstr ""

#: src/components/shortcuts-settings.jsx:62
msgid "List ID"
msgstr ""

#: src/components/shortcuts-settings.jsx:69
msgid "Variant"
msgstr "Variant"

#: src/components/shortcuts-settings.jsx:72
msgid "'local', 'bubble' or 'federated'"
msgstr "'local', 'bubble' or 'federated'"

#: src/components/shortcuts-settings.jsx:75
#: src/components/shortcuts-settings.jsx:84
#: src/components/shortcuts-settings.jsx:145
#: src/pages/login.jsx:193
msgid "Instance"
msgstr ""

#: src/components/shortcuts-settings.jsx:78
#: src/components/shortcuts-settings.jsx:87
#: src/components/shortcuts-settings.jsx:148
msgid "Optional, e.g. mastodon.social"
msgstr ""

#: src/components/shortcuts-settings.jsx:93
msgid "Search term"
msgstr ""

#: src/components/shortcuts-settings.jsx:96
msgid "Optional, unless for multi-column mode"
msgstr ""

#: src/components/shortcuts-settings.jsx:108
msgid "− Replies"
msgstr "− Replies"

#: src/components/shortcuts-settings.jsx:113
msgid "− Boosts"
msgstr "− Boosts"

#: src/components/shortcuts-settings.jsx:123
msgid "#"
msgstr "#"

#: src/components/shortcuts-settings.jsx:126
#: src/components/shortcuts-settings.jsx:136
msgid "e.g. PixelArt (Max 5, space-separated)"
msgstr ""

#: src/components/shortcuts-settings.jsx:140
#: src/pages/hashtag.jsx:355
msgid "Media only"
msgstr ""

#: src/components/shortcuts-settings.jsx:273
#: src/components/shortcuts.jsx:186
msgid "Shortcuts"
msgstr ""

#: src/components/shortcuts-settings.jsx:281
msgid "beta"
msgstr ""

#: src/components/shortcuts-settings.jsx:287
msgid "Specify a list of shortcuts that'll appear as:"
msgstr ""

#: src/components/shortcuts-settings.jsx:293
msgid "Floating button"
msgstr ""

#: src/components/shortcuts-settings.jsx:298
msgid "Tab/Menu bar"
msgstr ""

#: src/components/shortcuts-settings.jsx:303
msgid "Multi-column"
msgstr ""

#: src/components/shortcuts-settings.jsx:370
msgid "Not available in current view mode"
msgstr ""

#: src/components/shortcuts-settings.jsx:389
msgid "Move up"
msgstr ""

#: src/components/shortcuts-settings.jsx:405
msgid "Move down"
msgstr ""

#: src/components/shortcuts-settings.jsx:417
#: src/components/status.jsx:1306
#: src/pages/list.jsx:170
msgid "Edit"
msgstr ""

#: src/components/shortcuts-settings.jsx:438
msgid "Add more than one shortcut/column to make this work."
msgstr ""

#: src/components/shortcuts-settings.jsx:449
msgid "No columns yet. Tap on the Add column button."
msgstr ""

#: src/components/shortcuts-settings.jsx:450
msgid "No shortcuts yet. Tap on the Add shortcut button."
msgstr ""

#: src/components/shortcuts-settings.jsx:453
msgid "Not sure what to add?<0/>Try adding <1>Home / Following and Notifications</1> first."
msgstr ""

#: src/components/shortcuts-settings.jsx:481
msgid "Max {SHORTCUTS_LIMIT} columns"
msgstr ""

#: src/components/shortcuts-settings.jsx:482
msgid "Max {SHORTCUTS_LIMIT} shortcuts"
msgstr ""

#: src/components/shortcuts-settings.jsx:496
msgid "Import/export"
msgstr ""

#: src/components/shortcuts-settings.jsx:506
msgid "Add column…"
msgstr ""

#: src/components/shortcuts-settings.jsx:507
msgid "Add shortcut…"
msgstr ""

#: src/components/shortcuts-settings.jsx:554
msgid "Specific list is optional. For multi-column mode, list is required, else the column will not be shown."
msgstr ""

#: src/components/shortcuts-settings.jsx:555
msgid "For multi-column mode, search term is required, else the column will not be shown."
msgstr ""

#: src/components/shortcuts-settings.jsx:556
msgid "Multiple hashtags are supported. Space-separated."
msgstr ""

#: src/components/shortcuts-settings.jsx:625
msgid "Edit shortcut"
msgstr ""

#: src/components/shortcuts-settings.jsx:625
msgid "Add shortcut"
msgstr ""

#: src/components/shortcuts-settings.jsx:661
msgid "Timeline"
msgstr ""

#: src/components/shortcuts-settings.jsx:687
msgid "List"
msgstr ""

#: src/components/shortcuts-settings.jsx:826
msgid "Import/Export <0>Shortcuts</0>"
msgstr ""

#: src/components/shortcuts-settings.jsx:836
msgid "Import"
msgstr ""

#: src/components/shortcuts-settings.jsx:844
msgid "Paste shortcuts here"
msgstr ""

#: src/components/shortcuts-settings.jsx:860
msgid "Downloading saved shortcuts from instance server…"
msgstr ""

#: src/components/shortcuts-settings.jsx:889
msgid "Unable to download shortcuts"
msgstr ""

#: src/components/shortcuts-settings.jsx:892
msgid "Download shortcuts from instance server"
msgstr ""

#: src/components/shortcuts-settings.jsx:950
msgid "* Exists in current shortcuts"
msgstr ""

#: src/components/shortcuts-settings.jsx:955
msgid "List may not work if it's from a different account."
msgstr ""

#: src/components/shortcuts-settings.jsx:965
msgid "Invalid settings format"
msgstr ""

#: src/components/shortcuts-settings.jsx:973
msgid "Append to current shortcuts?"
msgstr ""

#: src/components/shortcuts-settings.jsx:976
msgid "Only shortcuts that don’t exist in current shortcuts will be appended."
msgstr ""

#: src/components/shortcuts-settings.jsx:998
msgid "No new shortcuts to import"
msgstr ""

#: src/components/shortcuts-settings.jsx:1013
msgid "Shortcuts imported. Exceeded max {SHORTCUTS_LIMIT}, so the rest are not imported."
msgstr ""

#: src/components/shortcuts-settings.jsx:1014
#: src/components/shortcuts-settings.jsx:1038
msgid "Shortcuts imported"
msgstr ""

#: src/components/shortcuts-settings.jsx:1024
msgid "Import & append…"
msgstr ""

#: src/components/shortcuts-settings.jsx:1032
msgid "Override current shortcuts?"
msgstr ""

#: src/components/shortcuts-settings.jsx:1033
msgid "Import shortcuts?"
msgstr ""

#: src/components/shortcuts-settings.jsx:1047
msgid "or override…"
msgstr ""

#: src/components/shortcuts-settings.jsx:1047
msgid "Import…"
msgstr ""

#: src/components/shortcuts-settings.jsx:1056
msgid "Export"
msgstr ""

#: src/components/shortcuts-settings.jsx:1071
msgid "Shortcuts copied"
msgstr ""

#: src/components/shortcuts-settings.jsx:1074
msgid "Unable to copy shortcuts"
msgstr ""

#: src/components/shortcuts-settings.jsx:1088
msgid "Shortcut settings copied"
msgstr ""

#: src/components/shortcuts-settings.jsx:1091
msgid "Unable to copy shortcut settings"
msgstr ""

#: src/components/shortcuts-settings.jsx:1097
#: src/components/status.jsx:3166
msgid "Copy"
msgstr ""

#: src/components/shortcuts-settings.jsx:1115
msgid "Sharing doesn't seem to work."
msgstr ""

#: src/components/shortcuts-settings.jsx:1121
msgid "Share"
msgstr ""

#: src/components/shortcuts-settings.jsx:1160
msgid "Saving shortcuts to instance server…"
msgstr ""

#: src/components/shortcuts-settings.jsx:1167
msgid "Shortcuts saved"
msgstr ""

#: src/components/shortcuts-settings.jsx:1172
msgid "Unable to save shortcuts"
msgstr ""

#: src/components/shortcuts-settings.jsx:1175
msgid "Sync to instance server"
msgstr ""

#: src/components/shortcuts-settings.jsx:1183
msgid "{0, plural, one {# character} other {# characters}}"
msgstr ""

#: src/components/shortcuts-settings.jsx:1195
msgid "Raw Shortcuts JSON"
msgstr ""

#: src/components/shortcuts-settings.jsx:1208
msgid "Import/export settings from/to instance server (Very experimental)"
msgstr ""

#: src/components/status.jsx:97
msgid "Restricted"
msgstr "Restricted"

#: src/components/status.jsx:102
msgid "P"
msgstr "P"

#: src/components/status.jsx:103
msgid "L"
msgstr "L"

#: src/components/status.jsx:104
msgid "U"
msgstr "U"

#: src/components/status.jsx:105
msgid "F"
msgstr "F"

#: src/components/status.jsx:106
msgid "R"
msgstr "R"

#: src/components/status.jsx:107
msgid "DM"
msgstr "DM"

#: src/components/status.jsx:518
msgid "<0/> <1>boosted</1>"
msgstr ""

#: src/components/status.jsx:617
msgid "Sorry, your current logged-in instance can't interact with this post from another instance."
msgstr ""

#: src/components/status.jsx:770
msgid "Unliked @{0}'s post"
msgstr "Unliked @{0}'s post"

#: src/components/status.jsx:771
msgid "Liked @{0}'s post"
msgstr "Liked @{0}'s post"

#: src/components/status.jsx:810
msgid "Unbookmarked @{0}'s post"
msgstr ""

#: src/components/status.jsx:811
msgid "Bookmarked @{0}'s post"
msgstr ""

#: src/components/status.jsx:920
#: src/components/status.jsx:984
#: src/components/status.jsx:2311
#: src/components/status.jsx:2343
msgid "Unboost"
msgstr ""

#: src/components/status.jsx:936
#: src/components/status.jsx:2326
msgid "Quote"
msgstr ""

#: src/components/status.jsx:944
#: src/components/status.jsx:2335
msgid "Some media have no descriptions."
msgstr ""

#: src/components/status.jsx:951
msgid "Old post (<0>{0}</0>)"
msgstr ""

#: src/components/status.jsx:970
#: src/components/status.jsx:1441
msgid "Unboosted @{0}'s post"
msgstr ""

#: src/components/status.jsx:971
#: src/components/status.jsx:1442
msgid "Boosted @{0}'s post"
msgstr ""

#: src/components/status.jsx:985
msgid "Boost…"
msgstr ""

#: src/components/status.jsx:997
#: src/components/status.jsx:1726
#: src/components/status.jsx:2356
msgid "Unlike"
msgstr ""

#: src/components/status.jsx:998
#: src/components/status.jsx:1726
#: src/components/status.jsx:1727
#: src/components/status.jsx:2356
#: src/components/status.jsx:2357
msgid "Like"
msgstr ""

#: src/components/status.jsx:1007
#: src/components/status.jsx:2368
msgid "Unbookmark"
msgstr ""

#: src/components/status.jsx:1129
msgid "Full view"
msgstr "Full view"

#: src/components/status.jsx:1183
#: src/components/status.jsx:3135
msgid "Embed post"
msgstr ""

#: src/components/status.jsx:1202
msgid "Show Edit History"
msgstr ""

#: src/components/status.jsx:1205
msgid "Edited: {editedDateText}"
msgstr ""

#: src/components/status.jsx:1225
msgid "Conversation unmuted"
msgstr ""

#: src/components/status.jsx:1225
msgid "Conversation muted"
msgstr ""

#: src/components/status.jsx:1232
msgid "Unable to unmute conversation"
msgstr ""

#: src/components/status.jsx:1233
msgid "Unable to mute conversation"
msgstr ""

#: src/components/status.jsx:1242
msgid "Unmute conversation"
msgstr ""

#: src/components/status.jsx:1249
msgid "Mute conversation"
msgstr ""

#: src/components/status.jsx:1265
msgid "Post unpinned from profile"
msgstr ""

#: src/components/status.jsx:1266
msgid "Post pinned to profile"
msgstr ""

#: src/components/status.jsx:1272
msgid "Unable to unpin post"
msgstr ""

#: src/components/status.jsx:1272
msgid "Unable to pin post"
msgstr ""

#: src/components/status.jsx:1281
msgid "Unpin from profile"
msgstr ""

#: src/components/status.jsx:1288
msgid "Pin to profile"
msgstr ""

#: src/components/status.jsx:1317
msgid "Delete this post?"
msgstr ""

#: src/components/status.jsx:1333
msgid "Post deleted"
msgstr ""

#: src/components/status.jsx:1337
msgid "Unable to delete post"
msgstr ""

#: src/components/status.jsx:1365
msgid "Report post…"
msgstr ""

#: src/components/status.jsx:1727
#: src/components/status.jsx:1763
#: src/components/status.jsx:2357
msgid "Liked"
msgstr ""

#: src/components/status.jsx:1760
#: src/components/status.jsx:2344
msgid "Boosted"
msgstr ""

#: src/components/status.jsx:1770
#: src/components/status.jsx:2369
msgid "Bookmarked"
msgstr ""

#: src/components/status.jsx:1774
msgid "Pinned"
msgstr ""

#: src/components/status.jsx:1819
#: src/components/status.jsx:2188
msgid "Deleted"
msgstr ""

#: src/components/status.jsx:1860
msgid "{repliesCount, plural, one {# reply} other {# replies}}"
msgstr ""

#: src/components/status.jsx:1880
msgid "{0}"
msgstr "{0}"

#: src/components/status.jsx:1947
#: src/components/status.jsx:2000
#: src/components/status.jsx:2086
msgid "Show less"
msgstr ""

#: src/components/status.jsx:1947
#: src/components/status.jsx:2000
msgid "Show content"
msgstr ""

#: src/components/status.jsx:2086
msgid "Show media"
msgstr ""

#: src/components/status.jsx:2209
msgid "Edited"
msgstr ""

#: src/components/status.jsx:2286
msgid "Comments"
msgstr ""

#. More from [Author]
#: src/components/status.jsx:2595
msgid "More from <0/>"
msgstr "More from <0/>"

#: src/components/status.jsx:2896
msgid "Edit History"
msgstr ""

#: src/components/status.jsx:2900
msgid "Failed to load history"
msgstr ""

#: src/components/status.jsx:2905
msgid "Loading…"
msgstr ""

#: src/components/status.jsx:3140
msgid "HTML Code"
msgstr ""

#: src/components/status.jsx:3157
msgid "HTML code copied"
msgstr ""

#: src/components/status.jsx:3160
msgid "Unable to copy HTML code"
msgstr ""

#: src/components/status.jsx:3172
msgid "Media attachments:"
msgstr ""

#: src/components/status.jsx:3194
msgid "Account Emojis:"
msgstr ""

#: src/components/status.jsx:3225
#: src/components/status.jsx:3270
msgid "static URL"
msgstr ""

#: src/components/status.jsx:3239
msgid "Emojis:"
msgstr ""

#: src/components/status.jsx:3284
msgid "Notes:"
msgstr ""

#: src/components/status.jsx:3288
msgid "This is static, unstyled and scriptless. You may need to apply your own styles and edit as needed."
msgstr ""

#: src/components/status.jsx:3294
msgid "Polls are not interactive, becomes a list with vote counts."
msgstr ""

#: src/components/status.jsx:3299
msgid "Media attachments can be images, videos, audios or any file types."
msgstr ""

#: src/components/status.jsx:3305
msgid "Post could be edited or deleted later."
msgstr ""

#: src/components/status.jsx:3311
msgid "Preview"
msgstr ""

#: src/components/status.jsx:3320
msgid "Note: This preview is lightly styled."
msgstr ""

#. [Name] [Visibility icon] boosted
#: src/components/status.jsx:3563
msgid "<0/> <1/> boosted"
msgstr ""

#: src/components/timeline.jsx:453
#: src/pages/settings.jsx:1149
msgid "New posts"
msgstr ""

#: src/components/timeline.jsx:554
#: src/pages/home.jsx:213
#: src/pages/notifications.jsx:824
#: src/pages/status.jsx:954
#: src/pages/status.jsx:1327
msgid "Try again"
msgstr ""

#: src/components/timeline.jsx:588
msgid "{0, plural, one {# Boost} other {# Boosts}}"
msgstr "{0, plural, one {# Boost} other {# Boosts}}"

#: src/components/timeline.jsx:593
msgid "Pinned posts"
msgstr "Pinned posts"

#: src/components/timeline.jsx:944
#: src/components/timeline.jsx:951
#: src/pages/catchup.jsx:1893
msgid "Thread"
msgstr ""

#: src/components/timeline.jsx:967
msgid "<0>{0}</0>"
msgstr "<0>{0}</0>"

#: src/components/translation-block.jsx:152
msgid "Auto-translated from {sourceLangText}"
msgstr ""

#: src/components/translation-block.jsx:190
msgid "Translating…"
msgstr ""

#: src/components/translation-block.jsx:193
msgid "Translate from {sourceLangText} (auto-detected)"
msgstr ""

#: src/components/translation-block.jsx:194
msgid "Translate from {sourceLangText}"
msgstr ""

#: src/components/translation-block.jsx:222
msgid "Auto ({0})"
msgstr ""

#: src/components/translation-block.jsx:235
msgid "Failed to translate"
msgstr ""

#: src/compose.jsx:32
msgid "Editing source status"
msgstr ""

#: src/compose.jsx:34
msgid "Replying to @{0}"
msgstr ""

#: src/compose.jsx:62
msgid "You may close this page now."
msgstr ""

#: src/compose.jsx:70
msgid "Close window"
msgstr ""

#: src/compose.jsx:86
msgid "Login required."
msgstr "Login required."

#: src/compose.jsx:90
#: src/pages/http-route.jsx:91
#: src/pages/login.jsx:270
msgid "Go home"
msgstr ""

#: src/pages/account-statuses.jsx:260
msgid "Account posts"
msgstr ""

#: src/pages/account-statuses.jsx:267
msgid "{accountDisplay} (+ Replies)"
msgstr ""

#: src/pages/account-statuses.jsx:269
msgid "{accountDisplay} (- Boosts)"
msgstr ""

#: src/pages/account-statuses.jsx:271
msgid "{accountDisplay} (#{tagged})"
msgstr ""

#: src/pages/account-statuses.jsx:273
msgid "{accountDisplay} (Media)"
msgstr ""

#: src/pages/account-statuses.jsx:279
msgid "{accountDisplay} ({monthYear})"
msgstr ""

#: src/pages/account-statuses.jsx:352
msgid "Clear filters"
msgstr ""

#: src/pages/account-statuses.jsx:355
msgid "Clear"
msgstr ""

#: src/pages/account-statuses.jsx:369
msgid "Showing post with replies"
msgstr ""

#: src/pages/account-statuses.jsx:374
msgid "+ Replies"
msgstr ""

#: src/pages/account-statuses.jsx:380
msgid "Showing posts without boosts"
msgstr ""

#: src/pages/account-statuses.jsx:385
msgid "- Boosts"
msgstr ""

#: src/pages/account-statuses.jsx:391
msgid "Showing posts with media"
msgstr ""

#: src/pages/account-statuses.jsx:408
msgid "Showing posts tagged with #{0}"
msgstr ""

#: src/pages/account-statuses.jsx:447
msgid "Showing posts in {0}"
msgstr ""

#: src/pages/account-statuses.jsx:536
msgid "Nothing to see here yet."
msgstr ""

#: src/pages/account-statuses.jsx:537
#: src/pages/public.jsx:114
#: src/pages/trending.jsx:449
msgid "Unable to load posts"
msgstr ""

#: src/pages/account-statuses.jsx:578
#: src/pages/account-statuses.jsx:608
msgid "Unable to fetch account info"
msgstr ""

#: src/pages/account-statuses.jsx:585
msgid "Switch to account's instance {0}"
msgstr ""

#: src/pages/account-statuses.jsx:615
msgid "Switch to my instance (<0>{currentInstance}</0>)"
msgstr ""

#: src/pages/account-statuses.jsx:677
msgid "Month"
msgstr ""

#: src/pages/accounts.jsx:55
msgid "Current"
msgstr ""

#: src/pages/accounts.jsx:101
msgid "Default"
msgstr ""

#: src/pages/accounts.jsx:123
msgid "Switch to this account"
msgstr "Switch to this account"

#: src/pages/accounts.jsx:132
msgid "Switch in new tab/window"
msgstr "Switch in new tab/window"

#: src/pages/accounts.jsx:146
msgid "View profile…"
msgstr ""

#: src/pages/accounts.jsx:163
msgid "Set as default"
msgstr ""

#: src/pages/accounts.jsx:173
msgid "Log out <0>@{0}</0>?"
msgstr ""

#: src/pages/accounts.jsx:196
msgid "Log out…"
msgstr ""

#: src/pages/accounts.jsx:209
msgid "Add an existing account"
msgstr ""

#: src/pages/accounts.jsx:216
msgid "Note: <0>Default</0> account will always be used for first load. Switched accounts will persist during the session."
msgstr ""

#: src/pages/bookmarks.jsx:25
msgid "No bookmarks yet. Go bookmark something!"
msgstr "No bookmarks yet. Go bookmark something!"

#: src/pages/bookmarks.jsx:26
msgid "Unable to load bookmarks."
msgstr ""

#: src/pages/catchup.jsx:54
msgid "last 1 hour"
msgstr ""

#: src/pages/catchup.jsx:55
msgid "last 2 hours"
msgstr ""

#: src/pages/catchup.jsx:56
msgid "last 3 hours"
msgstr ""

#: src/pages/catchup.jsx:57
msgid "last 4 hours"
msgstr ""

#: src/pages/catchup.jsx:58
msgid "last 5 hours"
msgstr ""

#: src/pages/catchup.jsx:59
msgid "last 6 hours"
msgstr ""

#: src/pages/catchup.jsx:60
msgid "last 7 hours"
msgstr ""

#: src/pages/catchup.jsx:61
msgid "last 8 hours"
msgstr ""

#: src/pages/catchup.jsx:62
msgid "last 9 hours"
msgstr ""

#: src/pages/catchup.jsx:63
msgid "last 10 hours"
msgstr ""

#: src/pages/catchup.jsx:64
msgid "last 11 hours"
msgstr ""

#: src/pages/catchup.jsx:65
msgid "last 12 hours"
msgstr ""

#: src/pages/catchup.jsx:66
msgid "beyond 12 hours"
msgstr ""

#: src/pages/catchup.jsx:73
msgid "Followed tags"
msgstr ""

#: src/pages/catchup.jsx:74
msgid "Groups"
msgstr ""

#: src/pages/catchup.jsx:596
msgid "Showing {selectedFilterCategory, select, all {all posts} original {original posts} replies {replies} boosts {boosts} followedTags {followed tags} groups {groups} filtered {filtered posts}}, {sortBy, select, createdAt {{sortOrder, select, asc {oldest} desc {latest}}} reblogsCount {{sortOrder, select, asc {fewest boosts} desc {most boosts}}} favouritesCount {{sortOrder, select, asc {fewest likes} desc {most likes}}} repliesCount {{sortOrder, select, asc {fewest replies} desc {most replies}}} density {{sortOrder, select, asc {least dense} desc {most dense}}}} first{groupBy, select, account {, grouped by authors} other {}}"
msgstr ""

#: src/pages/catchup.jsx:882
#: src/pages/catchup.jsx:906
msgid "Catch-up <0>beta</0>"
msgstr ""

#: src/pages/catchup.jsx:896
#: src/pages/catchup.jsx:1585
msgid "Help"
msgstr ""

#: src/pages/catchup.jsx:912
msgid "What is this?"
msgstr ""

#: src/pages/catchup.jsx:915
msgid "Catch-up is a separate timeline for your followings, offering a high-level view at a glance, with a simple, email-inspired interface to effortlessly sort and filter through posts."
msgstr ""

#: src/pages/catchup.jsx:926
msgid "Preview of Catch-up UI"
msgstr ""

#: src/pages/catchup.jsx:935
msgid "Let's catch up"
msgstr ""

#: src/pages/catchup.jsx:940
msgid "Let's catch up on the posts from your followings."
msgstr ""

#: src/pages/catchup.jsx:944
msgid "Show me all posts from…"
msgstr ""

#: src/pages/catchup.jsx:967
msgid "until the max"
msgstr ""

#: src/pages/catchup.jsx:997
msgid "Catch up"
msgstr ""

#: src/pages/catchup.jsx:1003
msgid "Overlaps with your last catch-up"
msgstr ""

#: src/pages/catchup.jsx:1015
msgid "Until the last catch-up ({0})"
msgstr ""

#: src/pages/catchup.jsx:1024
msgid "Note: your instance might only show a maximum of 800 posts in the Home timeline regardless of the time range. Could be less or more."
msgstr ""

#: src/pages/catchup.jsx:1034
msgid "Previously…"
msgstr ""

#: src/pages/catchup.jsx:1052
msgid "{0, plural, one {# post} other {# posts}}"
msgstr ""

#: src/pages/catchup.jsx:1062
msgid "Remove this catch-up?"
msgstr ""

#: src/pages/catchup.jsx:1083
msgid "Note: Only max 3 will be stored. The rest will be automatically removed."
msgstr ""

#: src/pages/catchup.jsx:1098
msgid "Fetching posts…"
msgstr ""

#: src/pages/catchup.jsx:1101
msgid "This might take a while."
msgstr ""

#: src/pages/catchup.jsx:1136
msgid "Reset filters"
msgstr ""

#: src/pages/catchup.jsx:1144
#: src/pages/catchup.jsx:1591
msgid "Top links"
msgstr ""

#: src/pages/catchup.jsx:1261
msgid "Shared by {0}"
msgstr ""

#: src/pages/catchup.jsx:1316
#: src/pages/mentions.jsx:147
#: src/pages/search.jsx:222
msgid "All"
msgstr ""

#: src/pages/catchup.jsx:1401
msgid "{0, plural, one {# author} other {# authors}}"
msgstr ""

#: src/pages/catchup.jsx:1413
msgid "Sort"
msgstr ""

#: src/pages/catchup.jsx:1444
msgid "Date"
msgstr ""

#: src/pages/catchup.jsx:1448
msgid "Density"
msgstr ""

#: src/pages/catchup.jsx:1486
msgid "Authors"
msgstr ""

#: src/pages/catchup.jsx:1487
msgid "None"
msgstr ""

#: src/pages/catchup.jsx:1503
msgid "Show all authors"
msgstr ""

#: src/pages/catchup.jsx:1554
msgid "You don't have to read everything."
msgstr ""

#: src/pages/catchup.jsx:1555
msgid "That's all."
msgstr ""

#: src/pages/catchup.jsx:1563
msgid "Back to top"
msgstr ""

#: src/pages/catchup.jsx:1594
msgid "Links shared by followings, sorted by shared counts, boosts and likes."
msgstr ""

#: src/pages/catchup.jsx:1600
msgid "Sort: Density"
msgstr ""

#: src/pages/catchup.jsx:1603
msgid "Posts are sorted by information density or depth. Shorter posts are \"lighter\" while longer posts are \"heavier\". Posts with photos are \"heavier\" than posts without photos."
msgstr ""

#: src/pages/catchup.jsx:1610
msgid "Group: Authors"
msgstr ""

#: src/pages/catchup.jsx:1613
msgid "Posts are grouped by authors, sorted by posts count per author."
msgstr ""

#: src/pages/catchup.jsx:1660
msgid "Next author"
msgstr ""

#: src/pages/catchup.jsx:1668
msgid "Previous author"
msgstr ""

#: src/pages/catchup.jsx:1684
msgid "Scroll to top"
msgstr ""

#: src/pages/catchup.jsx:1875
msgid "Filtered: {0}"
msgstr ""

#: src/pages/favourites.jsx:25
msgid "No likes yet. Go like something!"
msgstr "No likes yet. Go like something!"

#: src/pages/favourites.jsx:26
msgid "Unable to load likes."
msgstr ""

#: src/pages/filters.jsx:23
msgid "Home and lists"
msgstr ""

#: src/pages/filters.jsx:25
msgid "Public timelines"
msgstr ""

#: src/pages/filters.jsx:26
msgid "Conversations"
msgstr ""

#: src/pages/filters.jsx:27
msgid "Profiles"
msgstr ""

#: src/pages/filters.jsx:42
msgid "Never"
msgstr ""

#: src/pages/filters.jsx:103
#: src/pages/filters.jsx:228
msgid "New filter"
msgstr ""

#: src/pages/filters.jsx:151
msgid "{0, plural, one {# filter} other {# filters}}"
msgstr ""

#: src/pages/filters.jsx:166
msgid "Unable to load filters."
msgstr ""

#: src/pages/filters.jsx:170
msgid "No filters yet."
msgstr ""

#: src/pages/filters.jsx:177
msgid "Add filter"
msgstr ""

#: src/pages/filters.jsx:228
msgid "Edit filter"
msgstr ""

#: src/pages/filters.jsx:345
msgid "Unable to edit filter"
msgstr ""

#: src/pages/filters.jsx:346
msgid "Unable to create filter"
msgstr ""

#: src/pages/filters.jsx:355
msgid "Title"
msgstr ""

#: src/pages/filters.jsx:396
msgid "Whole word"
msgstr ""

#: src/pages/filters.jsx:422
msgid "No keywords. Add one."
msgstr ""

#: src/pages/filters.jsx:449
msgid "Add keyword"
msgstr ""

#: src/pages/filters.jsx:453
msgid "{0, plural, one {# keyword} other {# keywords}}"
msgstr ""

#: src/pages/filters.jsx:466
msgid "Filter from…"
msgstr ""

#: src/pages/filters.jsx:492
msgid "* Not implemented yet"
msgstr ""

#: src/pages/filters.jsx:498
msgid "Status: <0><1/></0>"
msgstr ""

#: src/pages/filters.jsx:507
msgid "Change expiry"
msgstr ""

#: src/pages/filters.jsx:507
msgid "Expiry"
msgstr ""

#: src/pages/filters.jsx:526
msgid "Filtered post will be…"
msgstr ""

#: src/pages/filters.jsx:536
msgid "minimized"
msgstr ""

#: src/pages/filters.jsx:546
msgid "hidden"
msgstr ""

#: src/pages/filters.jsx:563
msgid "Delete this filter?"
msgstr ""

#: src/pages/filters.jsx:576
msgid "Unable to delete filter."
msgstr ""

#: src/pages/filters.jsx:608
msgid "Expired"
msgstr ""

#: src/pages/filters.jsx:610
msgid "Expiring <0/>"
msgstr ""

#: src/pages/filters.jsx:614
msgid "Never expires"
msgstr ""

#: src/pages/followed-hashtags.jsx:70
msgid "{0, plural, one {# hashtag} other {# hashtags}}"
msgstr ""

#: src/pages/followed-hashtags.jsx:85
msgid "Unable to load followed hashtags."
msgstr ""

#: src/pages/followed-hashtags.jsx:89
msgid "No hashtags followed yet."
msgstr ""

#: src/pages/following.jsx:136
msgid "Nothing to see here."
msgstr ""

#: src/pages/following.jsx:137
#: src/pages/list.jsx:108
msgid "Unable to load posts."
msgstr ""

#: src/pages/hashtag.jsx:55
msgid "{hashtagTitle} (Media only) on {instance}"
msgstr ""

#: src/pages/hashtag.jsx:56
msgid "{hashtagTitle} on {instance}"
msgstr ""

#: src/pages/hashtag.jsx:58
msgid "{hashtagTitle} (Media only)"
msgstr ""

#: src/pages/hashtag.jsx:59
msgid "{hashtagTitle}"
msgstr ""

#: src/pages/hashtag.jsx:181
msgid "No one has posted anything with this tag yet."
msgstr ""

#: src/pages/hashtag.jsx:182
msgid "Unable to load posts with this tag"
msgstr ""

#: src/pages/hashtag.jsx:208
msgid "Unfollow #{hashtag}?"
msgstr "Unfollow #{hashtag}?"

#: src/pages/hashtag.jsx:223
msgid "Unfollowed #{hashtag}"
msgstr ""

#: src/pages/hashtag.jsx:238
msgid "Followed #{hashtag}"
msgstr ""

#: src/pages/hashtag.jsx:254
msgid "Following…"
msgstr ""

#: src/pages/hashtag.jsx:282
msgid "Unfeatured on profile"
msgstr ""

#: src/pages/hashtag.jsx:296
msgid "Unable to unfeature on profile"
msgstr ""

#: src/pages/hashtag.jsx:305
#: src/pages/hashtag.jsx:321
msgid "Featured on profile"
msgstr ""

#: src/pages/hashtag.jsx:328
msgid "Feature on profile"
msgstr ""

#: src/pages/hashtag.jsx:393
msgid "{TOTAL_TAGS_LIMIT, plural, other {Max # tags}}"
msgstr ""

#: src/pages/hashtag.jsx:396
msgid "Add hashtag"
msgstr ""

#: src/pages/hashtag.jsx:428
msgid "Remove hashtag"
msgstr ""

#: src/pages/hashtag.jsx:442
msgid "{SHORTCUTS_LIMIT, plural, one {Max # shortcut reached. Unable to add shortcut.} other {Max # shortcuts reached. Unable to add shortcut.}}"
msgstr ""

#: src/pages/hashtag.jsx:471
msgid "This shortcut already exists"
msgstr ""

#: src/pages/hashtag.jsx:474
msgid "Hashtag shortcut added"
msgstr ""

#: src/pages/hashtag.jsx:480
msgid "Add to Shortcuts"
msgstr ""

#: src/pages/hashtag.jsx:486
#: src/pages/public.jsx:163
#: src/pages/trending.jsx:478
msgid "Enter a new instance e.g. \"mastodon.social\""
msgstr ""

#: src/pages/hashtag.jsx:489
#: src/pages/public.jsx:166
#: src/pages/trending.jsx:481
msgid "Invalid instance"
msgstr ""

#: src/pages/hashtag.jsx:503
#: src/pages/public.jsx:180
#: src/pages/trending.jsx:493
msgid "Go to another instance…"
msgstr ""

#: src/pages/hashtag.jsx:516
#: src/pages/public.jsx:193
#: src/pages/trending.jsx:504
msgid "Go to my instance (<0>{currentInstance}</0>)"
msgstr ""

#: src/pages/home.jsx:209
msgid "Unable to fetch notifications."
msgstr ""

#: src/pages/home.jsx:229
msgid "<0>New</0> <1>Follow Requests</1>"
msgstr ""

#: src/pages/home.jsx:235
msgid "See all"
msgstr ""

#: src/pages/http-route.jsx:68
msgid "Resolving…"
msgstr ""

#: src/pages/http-route.jsx:79
msgid "Unable to resolve URL"
msgstr ""

#: src/pages/list.jsx:107
msgid "Nothing yet."
msgstr ""

#: src/pages/list.jsx:176
#: src/pages/list.jsx:279
msgid "Manage members"
msgstr ""

#: src/pages/list.jsx:313
msgid "Remove <0>@{0}</0> from list?"
msgstr ""

#: src/pages/list.jsx:359
msgid "Remove…"
msgstr ""

#: src/pages/lists.jsx:93
msgid "{0, plural, one {# list} other {# lists}}"
msgstr ""

#: src/pages/lists.jsx:108
msgid "No lists yet."
msgstr ""

#: src/pages/login.jsx:109
#: src/pages/login.jsx:122
msgid "Failed to register application"
msgstr "Failed to register application"

#: src/pages/login.jsx:208
msgid "instance domain"
msgstr "instance domain"

#: src/pages/login.jsx:232
msgid "e.g. “mastodon.social”"
msgstr ""

#: src/pages/login.jsx:243
msgid "Failed to log in. Please try again or try another instance."
msgstr ""

#: src/pages/login.jsx:255
msgid "Continue with {selectedInstanceText}"
msgstr ""

#: src/pages/login.jsx:256
msgid "Continue"
msgstr ""

#: src/pages/login.jsx:264
msgid "Don't have an account? Create one!"
msgstr ""

#: src/pages/mentions.jsx:20
msgid "Private mentions"
msgstr ""

#: src/pages/mentions.jsx:159
msgid "Private"
msgstr ""

#: src/pages/mentions.jsx:169
msgid "No one mentioned you :("
msgstr ""

#: src/pages/mentions.jsx:170
msgid "Unable to load mentions."
msgstr ""

#: src/pages/notifications.jsx:103
msgid "You don't follow"
msgstr ""

#: src/pages/notifications.jsx:104
msgid "Who don't follow you"
msgstr ""

#: src/pages/notifications.jsx:105
msgid "With a new account"
msgstr ""

#: src/pages/notifications.jsx:106
msgid "Who unsolicitedly private mention you"
msgstr ""

#: src/pages/notifications.jsx:107
msgid "Who are limited by server moderators"
msgstr ""

#: src/pages/notifications.jsx:538
#: src/pages/notifications.jsx:872
msgid "Notifications settings"
msgstr ""

#: src/pages/notifications.jsx:556
msgid "New notifications"
msgstr ""

#: src/pages/notifications.jsx:567
msgid "{0, plural, one {Announcement} other {Announcements}}"
msgstr ""

#: src/pages/notifications.jsx:614
#: src/pages/settings.jsx:1137
msgid "Follow requests"
msgstr ""

#: src/pages/notifications.jsx:619
msgid "{0, plural, one {# follow request} other {# follow requests}}"
msgstr ""

#: src/pages/notifications.jsx:674
msgid "{0, plural, one {Filtered notifications from # person} other {Filtered notifications from # people}}"
msgstr ""

#: src/pages/notifications.jsx:740
msgid "Only mentions"
msgstr ""

#: src/pages/notifications.jsx:744
msgid "Today"
msgstr ""

#: src/pages/notifications.jsx:749
msgid "You're all caught up."
msgstr ""

#: src/pages/notifications.jsx:772
msgid "Yesterday"
msgstr ""

#: src/pages/notifications.jsx:820
msgid "Unable to load notifications"
msgstr ""

#: src/pages/notifications.jsx:899
msgid "Notifications settings updated"
msgstr ""

#: src/pages/notifications.jsx:907
msgid "Filter out notifications from people:"
msgstr ""

#: src/pages/notifications.jsx:921
msgid "Filter"
msgstr ""

#: src/pages/notifications.jsx:924
msgid "Ignore"
msgstr ""

#: src/pages/notifications.jsx:997
msgid "Updated <0>{0}</0>"
msgstr ""

#: src/pages/notifications.jsx:1065
msgid "View notifications from <0>@{0}</0>"
msgstr ""

#: src/pages/notifications.jsx:1086
msgid "Notifications from <0>@{0}</0>"
msgstr ""

#: src/pages/notifications.jsx:1153
msgid "Notifications from @{0} will not be filtered from now on."
msgstr ""

#: src/pages/notifications.jsx:1158
msgid "Unable to accept notification request"
msgstr ""

#: src/pages/notifications.jsx:1163
msgid "Allow"
msgstr ""

#: src/pages/notifications.jsx:1183
msgid "Notifications from @{0} will not show up in Filtered notifications from now on."
msgstr ""

#: src/pages/notifications.jsx:1188
msgid "Unable to dismiss notification request"
msgstr ""

#: src/pages/notifications.jsx:1193
msgid "Dismiss"
msgstr ""

#: src/pages/notifications.jsx:1208
msgid "Dismissed"
msgstr ""

#: src/pages/public.jsx:26
msgid "Local timeline ({instance})"
msgstr ""

#: src/pages/public.jsx:27
msgid "Bubble timeline ({instance})"
msgstr "Bubble timeline ({instance})"

#: src/pages/public.jsx:28
msgid "Federated timeline ({instance})"
msgstr ""

#: src/pages/public.jsx:31
msgid "Local timeline"
msgstr ""

#: src/pages/public.jsx:32
msgid "Bubble timeline"
msgstr "Bubble timeline"

#: src/pages/public.jsx:33
msgid "Federated timeline"
msgstr ""

#: src/pages/public.jsx:113
msgid "No one has posted anything yet."
msgstr ""

#: src/pages/public.jsx:139
msgid "Switch to Local"
msgstr ""

#: src/pages/public.jsx:147
msgid "Switch to Bubble"
msgstr "Switch to Bubble"

#: src/pages/public.jsx:155
msgid "Switch to Federated"
msgstr ""

#: src/pages/search.jsx:43
msgid "Search: {q} (Posts)"
msgstr ""

#: src/pages/search.jsx:46
msgid "Search: {q} (Accounts)"
msgstr ""

#: src/pages/search.jsx:49
msgid "Search: {q} (Hashtags)"
msgstr ""

#: src/pages/search.jsx:52
msgid "Search: {q}"
msgstr ""

#: src/pages/search.jsx:232
#: src/pages/search.jsx:314
msgid "Hashtags"
msgstr ""

#: src/pages/search.jsx:264
#: src/pages/search.jsx:318
#: src/pages/search.jsx:388
msgid "See more"
msgstr ""

#: src/pages/search.jsx:290
msgid "See more accounts"
msgstr ""

#: src/pages/search.jsx:304
msgid "No accounts found."
msgstr ""

#: src/pages/search.jsx:360
msgid "See more hashtags"
msgstr ""

#: src/pages/search.jsx:374
msgid "No hashtags found."
msgstr ""

#: src/pages/search.jsx:418
msgid "See more posts"
msgstr ""

#: src/pages/search.jsx:432
msgid "No posts found."
msgstr ""

#: src/pages/search.jsx:476
msgid "Enter your search term or paste a URL above to get started."
msgstr ""

#: src/pages/settings.jsx:81
msgid "Settings"
msgstr ""

#: src/pages/settings.jsx:90
msgid "Appearance"
msgstr ""

#: src/pages/settings.jsx:166
msgid "Light"
msgstr ""

#: src/pages/settings.jsx:177
msgid "Dark"
msgstr ""

#: src/pages/settings.jsx:190
msgid "Auto"
msgstr ""

#: src/pages/settings.jsx:200
msgid "Text size"
msgstr ""

#. Preview of one character, in smallest size
#. Preview of one character, in largest size
#: src/pages/settings.jsx:205
#: src/pages/settings.jsx:230
msgid "A"
msgstr ""

#: src/pages/settings.jsx:244
msgid "Display language"
msgstr ""

#: src/pages/settings.jsx:253
msgid "Volunteer translations"
msgstr "Volunteer translations"

#: src/pages/settings.jsx:264
msgid "Posting"
msgstr ""

#: src/pages/settings.jsx:271
msgid "Default visibility"
msgstr ""

#: src/pages/settings.jsx:272
#: src/pages/settings.jsx:318
msgid "Synced"
msgstr ""

#: src/pages/settings.jsx:297
msgid "Failed to update posting privacy"
msgstr ""

#: src/pages/settings.jsx:320
msgid "Synced to your instance server's settings. <0>Go to your instance ({instance}) for more settings.</0>"
msgstr ""

#: src/pages/settings.jsx:335
msgid "Experiments"
msgstr ""

#: src/pages/settings.jsx:348
msgid "Auto refresh timeline posts"
msgstr ""

#: src/pages/settings.jsx:360
msgid "Boosts carousel"
msgstr ""

#: src/pages/settings.jsx:376
msgid "Post translation"
msgstr ""

#: src/pages/settings.jsx:387
msgid "Translate to"
msgstr ""

#: src/pages/settings.jsx:398
msgid "System language ({systemTargetLanguageText})"
msgstr ""

#: src/pages/settings.jsx:424
msgid "{0, plural, =0 {Hide \"Translate\" button for:} other {Hide \"Translate\" button for (#):}}"
msgstr ""

#: src/pages/settings.jsx:478
msgid "Note: This feature uses external translation services, powered by <0>Lingva API</0> & <1>Lingva Translate</1>."
msgstr ""

#: src/pages/settings.jsx:512
msgid "Auto inline translation"
msgstr ""

#: src/pages/settings.jsx:516
msgid "Automatically show translation for posts in timeline. Only works for <0>short</0> posts without content warning, media and poll."
msgstr ""

#: src/pages/settings.jsx:536
msgid "GIF Picker for composer"
msgstr ""

#: src/pages/settings.jsx:540
msgid "Note: This feature uses external GIF search service, powered by <0>GIPHY</0>. G-rated (suitable for viewing by all ages), tracking parameters are stripped, referrer information is omitted from requests, but search queries and IP address information will still reach their servers."
msgstr ""

#: src/pages/settings.jsx:569
msgid "Image description generator"
msgstr ""

#: src/pages/settings.jsx:574
msgid "Only for new images while composing new posts."
msgstr ""

#: src/pages/settings.jsx:581
msgid "Note: This feature uses external AI service, powered by <0>img-alt-api</0>. May not work well. Only for images and in English."
msgstr ""

#: src/pages/settings.jsx:607
msgid "Server-side grouped notifications"
msgstr ""

#: src/pages/settings.jsx:611
msgid "Alpha-stage feature. Potentially improved grouping window but basic grouping logic."
msgstr ""

#: src/pages/settings.jsx:632
msgid "\"Cloud\" import/export for shortcuts settings"
msgstr ""

#: src/pages/settings.jsx:637
msgid "⚠️⚠️⚠️ Very experimental.<0/>Stored in your own profile’s notes. Profile (private) notes are mainly used for other profiles, and hidden for own profile."
msgstr ""

#: src/pages/settings.jsx:648
msgid "Note: This feature uses currently-logged-in instance server API."
msgstr ""

#: src/pages/settings.jsx:665
msgid "Cloak mode <0>(<1>Text</1> → <2>████</2>)</0>"
msgstr ""

#: src/pages/settings.jsx:674
msgid "Replace text as blocks, useful when taking screenshots, for privacy reasons."
msgstr ""

#: src/pages/settings.jsx:690
msgid "Numberless mode"
msgstr "Numberless mode"

#: src/pages/settings.jsx:694
msgid "Removes all post and user metrics from the client."
msgstr "Removes all post and user metrics from the client."

#: src/pages/settings.jsx:718
msgid "About"
msgstr ""

#: src/pages/settings.jsx:757
msgid "<0>Built</0> by <1>@cheeaun</1>"
msgstr ""

#: src/pages/settings.jsx:786
msgid "Sponsor"
msgstr ""

#: src/pages/settings.jsx:794
msgid "Donate"
msgstr ""

#: src/pages/settings.jsx:802
msgid "Privacy Policy"
msgstr ""

#: src/pages/settings.jsx:809
msgid "<0>Site:</0> {0}"
msgstr ""

#: src/pages/settings.jsx:816
msgid "<0>Version:</0> <1/> {0}"
msgstr ""

#: src/pages/settings.jsx:831
msgid "Version string copied"
msgstr ""

#: src/pages/settings.jsx:834
msgid "Unable to copy version string"
msgstr ""

#: src/pages/settings.jsx:1034
#: src/pages/settings.jsx:1039
msgid "Failed to update subscription. Please try again."
msgstr ""

#: src/pages/settings.jsx:1045
msgid "Failed to remove subscription. Please try again."
msgstr ""

#: src/pages/settings.jsx:1052
msgid "Push Notifications (beta)"
msgstr ""

#: src/pages/settings.jsx:1074
msgid "Push notifications are blocked. Please enable them in your browser settings."
msgstr ""

#: src/pages/settings.jsx:1083
msgid "Allow from <0>{0}</0>"
msgstr ""

#: src/pages/settings.jsx:1092
msgid "anyone"
msgstr ""

#: src/pages/settings.jsx:1096
msgid "people I follow"
msgstr ""

#: src/pages/settings.jsx:1100
msgid "followers"
msgstr ""

#: src/pages/settings.jsx:1133
msgid "Follows"
msgstr ""

#: src/pages/settings.jsx:1141
msgid "Polls"
msgstr ""

#: src/pages/settings.jsx:1145
msgid "Post edits"
msgstr ""

#: src/pages/settings.jsx:1166
msgid "Push permission was not granted since your last login. You'll need to <0><1>log in</1> again to grant push permission</0>."
msgstr ""

#: src/pages/settings.jsx:1182
msgid "NOTE: Push notifications only work for <0>one account</0>."
msgstr ""

#: src/pages/status.jsx:567
msgid "Post"
msgstr ""

#: src/pages/status.jsx:788
msgid "You're not logged in. Interactions (reply, boost, etc) are not possible."
msgstr ""

#: src/pages/status.jsx:808
msgid "This post is from another instance (<0>{instance}</0>). Interactions (reply, boost, etc) are not possible."
msgstr ""

#: src/pages/status.jsx:836
msgid "Error: {e}"
msgstr ""

#: src/pages/status.jsx:843
msgid "Switch to my instance to enable interactions"
msgstr ""

#: src/pages/status.jsx:945
msgid "Unable to load replies."
msgstr ""

#: src/pages/status.jsx:1057
msgid "Back"
msgstr ""

#: src/pages/status.jsx:1088
msgid "Go to main post"
msgstr ""

#: src/pages/status.jsx:1111
msgid "{0} posts above ‒ Go to top"
msgstr ""

#: src/pages/status.jsx:1154
#: src/pages/status.jsx:1217
msgid "Switch to Side Peek view"
msgstr ""

#: src/pages/status.jsx:1218
msgid "Switch to Full view"
msgstr ""

#: src/pages/status.jsx:1236
msgid "Show all sensitive content"
msgstr ""

#: src/pages/status.jsx:1241
msgid "Experimental"
msgstr ""

#: src/pages/status.jsx:1250
msgid "Unable to switch"
msgstr ""

#: src/pages/status.jsx:1257
msgid "Switch to post's instance ({0})"
msgstr ""

#: src/pages/status.jsx:1260
msgid "Switch to post's instance"
msgstr ""

#: src/pages/status.jsx:1318
msgid "Unable to load post"
msgstr ""

#: src/pages/status.jsx:1435
msgid "{0, plural, one {# reply} other {<0>{1}</0> replies}}"
msgstr ""

#: src/pages/status.jsx:1453
msgid "{totalComments, plural, one {# comment} other {<0>{0}</0> comments}}"
msgstr ""

#: src/pages/status.jsx:1475
msgid "View post with its replies"
msgstr ""

#: src/pages/trending.jsx:71
msgid "Trending ({instance})"
msgstr ""

#: src/pages/trending.jsx:228
msgid "Trending News"
msgstr ""

#. By [Author]
#: src/pages/trending.jsx:347
msgid "By {0}"
msgstr "By {0}"

#: src/pages/trending.jsx:408
msgid "Back to showing trending posts"
msgstr ""

#: src/pages/trending.jsx:413
msgid "Showing posts mentioning <0>{0}</0>"
msgstr ""

#: src/pages/trending.jsx:425
msgid "Trending posts"
msgstr ""

#: src/pages/trending.jsx:448
msgid "No trending posts."
msgstr ""

#: src/pages/trending.jsx:449
msgid "Your server does not seem to support the Trending timeline"
msgstr "Your server does not seem to support the Trending timeline"

#: src/pages/welcome.jsx:53
msgid "A minimalistic opinionated Mastodon web client."
msgstr ""

#: src/pages/welcome.jsx:64
msgid "Log in with Mastodon"
msgstr ""

#: src/pages/welcome.jsx:70
msgid "Sign up"
msgstr ""

#: src/pages/welcome.jsx:77
msgid "Connect your existing Mastodon/Fediverse account.<0/>Your credentials are not stored on this server."
msgstr ""

#: src/pages/welcome.jsx:94
msgid "<0>Built</0> by <1>@cheeaun</1>. <2>Privacy Policy</2>."
msgstr ""

#: src/pages/welcome.jsx:125
msgid "Screenshot of Boosts Carousel"
msgstr ""

#: src/pages/welcome.jsx:129
msgid "Boosts Carousel"
msgstr ""

#: src/pages/welcome.jsx:132
msgid "Visually separate original posts and re-shared posts (boosted posts)."
msgstr ""

#: src/pages/welcome.jsx:141
msgid "Screenshot of nested comments thread"
msgstr ""

#: src/pages/welcome.jsx:145
msgid "Nested comments thread"
msgstr ""

#: src/pages/welcome.jsx:148
msgid "Effortlessly follow conversations. Semi-collapsible replies."
msgstr ""

#: src/pages/welcome.jsx:156
msgid "Screenshot of grouped notifications"
msgstr ""

#: src/pages/welcome.jsx:160
msgid "Grouped notifications"
msgstr ""

#: src/pages/welcome.jsx:163
msgid "Similar notifications are grouped and collapsed to reduce clutter."
msgstr ""

#: src/pages/welcome.jsx:172
msgid "Screenshot of multi-column UI"
msgstr ""

#: src/pages/welcome.jsx:176
msgid "Single or multi-column"
msgstr ""

#: src/pages/welcome.jsx:179
msgid "By default, single column for zen-mode seekers. Configurable multi-column for power users."
msgstr ""

#: src/pages/welcome.jsx:188
msgid "Screenshot of multi-hashtag timeline with a form to add more hashtags"
msgstr ""

#: src/pages/welcome.jsx:192
msgid "Multi-hashtag timeline"
msgstr ""

#: src/pages/welcome.jsx:195
msgid "Up to 5 hashtags combined into a single timeline."
msgstr ""

#: src/utils/open-compose.js:24
msgid "Looks like your browser is blocking popups."
msgstr ""

#: src/utils/shorten-number.js:8
msgid "Several"
msgstr "Several"

#: src/utils/show-compose.js:16
msgid "A draft post is currently minimized. Post or discard it before creating a new one."
msgstr ""

#: src/utils/show-compose.js:21
msgid "A post is currently open. Post or discard it before creating a new one."
msgstr ""
